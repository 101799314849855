import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { EStorage } from "../../../components/utils/estorage.service";
import { CryptoservService } from "../../../components/utils/cryptoserv.service";
import { EncuestaService } from "../../../components/service/encuesta.service";
import { ExamenService } from "../../../components/service/examen.service";
import { ExportService } from "../../../components/service/export.service";

@Component({
  selector: "app-admin-info",
  templateUrl: "./adminInfo.component.html",
  styleUrls: ["./adminInfo.component.scss"],
})
export class AdminInfoComponent implements OnInit {
  constructor(
    private _router: Router,
    private _EStorage: EStorage,
    private cryptoServ: CryptoservService,
    private _exportService: ExportService,
    private _encuestaService: EncuestaService,
    private _examenService: ExamenService
  ) {}

  isCargando = false;

  item1 = [
    { text: "1.OBJETIVO DEL CURSO PARA SU DESEMPEÑO LABORAL" },
    { text: "2. PLANIFICACIÓN DEL CURSO" },
    { text: "3.FORMA EN QUE FUERON ABORDADOS LOS CONTENIDOS" },
    { text: "4.APORTE DE LAS ACTIVIDADES COMO REFORZAMIENTO" },
  ];

  item2 = [
    {
      text: "1.DESEMPEÑO GENERAL DEL RELATOR PARA INSTRUIR AL PARTICIPANTE EN EL USO DE LA APLICACIÓN ",
    },
  ];

  item3 = [
    {
      text: "1.- LOS CONTENIDOS ENTREGADOS, LAS ACTIVIDADES Y VIDEOS DE LA APLICACIÓN FUERON ÚTILES PARA LA COMPRENSIÓN DEL CURSO",
    },
  ];

  ngOnInit() {
    const validado = this._EStorage.getItem("validadoAdm");
    if (validado == null || validado != "true") {
      this._router.navigate(["/"]);
      return;
    }
  }

  iniciar() {
    this._router.navigate(["/encuesta"]);
  }

  async descargarExamen(tipo) {
    this.isCargando = true;
    var nombreColeccion  = "examenJardin";
    var baseNombreArchivo = "respuestaJardin";
    if(tipo == "junaeb"){
      nombreColeccion = "examenJunaeb";
      baseNombreArchivo = "respuestaJunaeb";
    }
    var info = [];
    var orderExport = [];
    info = await this._examenService.getAllExport(nombreColeccion);
    for (let i = 0; i < info.length; i++) {
      var dataProv = info[i].data();
      var rut = info[i].id.toString();
      rut = rut.replace(/ /g, "");
      rut = rut.trim();
      console.log(dataProv);
      const fechaInicioExamen = dataProv["fechaInicioExamen"];
      const dateInicio = new Date(fechaInicioExamen.seconds * 1000 + fechaInicioExamen.nanoseconds / 1000000);
      const localDateInicioString = dateInicio.toLocaleString();
      const fechaTerminoExamen = dataProv["fechaTerminoExamen"];
      const dateTermino = new Date(fechaTerminoExamen.seconds * 1000 + fechaTerminoExamen.nanoseconds / 1000000);
      const localDateTerminoString = dateTermino.toLocaleString();
      const nombre = dataProv["nombre"];
      const respuesta = dataProv["respuesta"];
      const cantPreguntasCorrecta = dataProv["cantPreguntasCorrecta"];
      const preguntaObj = JSON.parse(respuesta);
      let infoEnviar = {
        rut: rut,
        nombre: nombre,
        fechaInicioExamen: localDateInicioString,
        fechaTerminoExamen: localDateTerminoString,
        correctas: cantPreguntasCorrecta,
      };
      for (let index = 0; index < preguntaObj.length; index++) {
        const element = preguntaObj[index];
        infoEnviar["pregunta" + (index + 1)] = element.pregunta;
        infoEnviar["respuesta" + (index + 1)] = element.respuesta;
        infoEnviar["correcta" + (index + 1)] = element.correcta;
      }
      orderExport.push(
        infoEnviar
      );
    }
    const date = new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses comienzan desde 0
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const fileName = `${baseNombreArchivo}-${day}-${month}-${year}-${hours}-${minutes}`;

    await this._exportService.exportExcel(orderExport, fileName);
    this.isCargando = false;
  }

  async descargarEncuesta(tipo) {
    this.isCargando = true;
    var nombreColeccion  = "encuestaJardin";
    var baseNombreArchivo = "encuestaJardin";
    if(tipo == "junaeb"){
      nombreColeccion = "encuestaJunaeb";
      baseNombreArchivo = "encuestaJunaeb";
    }
    var info = [];
    var orderExport = [];
    info = await this._examenService.getAllExport(nombreColeccion);
    for (let i = 0; i < info.length; i++) {
      var dataProv = info[i].data();
      var rut = info[i].id.toString();
      rut = rut.replace(/ /g, "");
      rut = rut.trim();
      console.log(dataProv);
      const fecUpdate = dataProv["fecUpdate"];
      const dateInicio = new Date(fecUpdate.seconds * 1000 + fecUpdate.nanoseconds / 1000000);
      const localDateInicioString = dateInicio.toLocaleString();
      const nombre = dataProv["nombre"];
      const encuesta = dataProv["encuesta"];
      const encuestaObj = JSON.parse(encuesta);
      console.log(encuestaObj);
      let infoEnviar = {
        rut: rut,
        nombre: nombre,
        fechaEnvio: localDateInicioString,
        item1preg1: this.item1[0].text,
        item1resp1: encuestaObj.item1.preg0,
        item1preg2: this.item1[1].text,
        item1resp2: encuestaObj.item1.preg1,
        item1preg3: this.item1[2].text,
        item1resp3: encuestaObj.item1.preg2,
        item1preg4: this.item1[3].text,
        item1resp4: encuestaObj.item1.preg3,
        item2preg1: this.item2[0].text,
        item2resp2: encuestaObj.item2.preg0,
        item3preg1: this.item3[0].text,
        item3resp2: encuestaObj.item3.preg0,
        observaciones: encuestaObj.obs
      };
      orderExport.push(
        infoEnviar
      );
    }
    const date = new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses comienzan desde 0
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const fileName = `${baseNombreArchivo}-${day}-${month}-${year}-${hours}-${minutes}`;

    await this._exportService.exportExcel(orderExport, fileName);
    this.isCargando = false;
  }

  descargarEncuestaJunaeb() {}

  descargarEncuestaJardines() {}

  convertToUTC4String(timestamp: any): string {
    // Crear una fecha a partir de los segundos
    const date = new Date(
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
    );
    // Obtener el tiempo UTC
    const utcTime = date.getTime() + date.getTimezoneOffset() * 60000;
    // Convertir a UTC-4
    const utc4Time = new Date(utcTime - 4 * 3600000);
    // Crear un formateador para la fecha y la hora
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
      timeZone: "America/Caracas", // Caracas es UTC-4
      timeZoneName: "short",
    };
    const formatter = new Intl.DateTimeFormat("es-ES", options);
    // Formatear la fecha
    const formattedDate = formatter.format(utc4Time);
    return formattedDate;
  }
}
